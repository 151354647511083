import React from 'react';

import PageLayout from '../components/blocks/page-layout';
import Title from '../components/elements/title';
import { useLocale } from '../hooks/useLocale';

export default function Page() {
  const locale = useLocale();

  const pageNotFound = 'Pagina niet gevonden';
  const text = 'Deze pagina kan helaas niet gevonden worden, deze bestaat waarschijnlijk niet meer of is verhuist.';
  const viewProducts = 'Ontdek onze producten';

  return (
    <PageLayout title={pageNotFound}>
      <Title>{pageNotFound}</Title>
      <p>{text}</p>
      <div className="my-4">
        <a
          href={`/${locale}/products`}
          className="py-2 px-4 flex justify-center items-center hover:text-white hover:bg-raw-umber text-raw-umber border border-raw-umber w-64 max-w-full"
        >
          {viewProducts}
        </a>
      </div>
    </PageLayout>
  );
}
